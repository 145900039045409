import React from 'react';

function Footer() {
    const imageStyle = {
        width: '100px',
        height: '150px',
        objectFit: 'contain',
        margin: '0 40px',
    };
    return (
        <footer>
            <div id="footImg" style={{ display: 'flex', justifyContent: 'center',alignItems:'center' }}>
                <img src="https://eston-file.oss-cn-hangzhou.aliyuncs.com/weekly/coverUrl/20231102-113913.jpeg"
                     alt="Image 3" style={imageStyle}/>
                {/*https://eston-file.oss-cn-hangzhou.aliyuncs.com/weekly/coverUrl/20231102-113913.jpeg*/}
                <img src="https://eston-file.oss-cn-hangzhou.aliyuncs.com/weekly/coverUrl/20231102-113911.jpeg"
                     alt="Image 2" style={imageStyle}/>
                <img src="https://eston-file.oss-cn-hangzhou.aliyuncs.com/weekly/coverUrl/20231102-113906.jpeg"
                     alt="Image 1" style={imageStyle}/>
                <img src="https://eston-file.oss-cn-hangzhou.aliyuncs.com/weekly/coverUrl/20231102-113917.jpeg"
                     alt="Image 4" style={imageStyle}/>

            </div>
        </footer>
    );
}

export default Footer;
